import React from "react";
export const Sections = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, company, email, message } = event.target;

    const data = {
      name: name.value,
      company: company.value,
      email: email.value,
      message: message.value,
    };
    console.log("data:::", data);
  };
  return (
    <>
      {/* <!-- About Us Section --> */}
      <section id="about">
        <h2 className="aboutHeader">About CreativeClick Multimedia</h2>
        <p className="center">
          At CreativeClick Multimedia, we empower software teams with automated
          testing solutions that simplify and enhance QA processes. From
          functional to performance testing, our platform accelerates quality
          assurance for digital products of tomorrow.
        </p>
      </section>
      {/* <!-- Key Benefits Section --> */}
      <section className="key-benefits">
        <h2>Why Choose Us?</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <h3>Faster Time to Market</h3>
            <p>
              Automate complex test cases to catch issues early and speed up
              your release cycle.
            </p>
          </div>
          <div className="benefit-item">
            <h3>Comprehensive Test Coverage</h3>
            <p>
              Ensure no bug slips through the cracks with end-to-end testing
              that covers all platforms.
            </p>
          </div>
          <div className="benefit-item">
            <h3>Scalable & Reliable</h3>
            <p>
              Our infrastructure scales with your needs, from startups to
              enterprise-level testing.
            </p>
          </div>
          <div className="benefit-item">
            <h3>Cost-effective</h3>
            <p>
              Reduce the cost of manual testing without compromising on quality.
            </p>
          </div>
        </div>
      </section>

      {/* <!-- Services Section --> */}
      <section id="services" className="services">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service-item">
            <h3>Functional Testing</h3>
            <p>
              Ensure that your app behaves as expected in all environments and
              scenarios.
            </p>
          </div>
          <div className="service-item">
            <h3>Performance Testing</h3>
            <p>
              Measure how well your application performs under stress and
              optimize for speed.
            </p>
          </div>
          <div className="service-item">
            <h3>API Testing</h3>
            <p>
              Automate API tests to ensure proper communication and
              functionality of your services.
            </p>
          </div>
          <div className="service-item">
            <h3>Regression Testing</h3>
            <p>
              Identify and fix bugs introduced by new code changes, preventing
              production issues.
            </p>
          </div>
        </div>
      </section>

      {/* <!-- How It Works Section --> */}
      <section id="how-it-works" className="how-it-works">
        <h2>How It Works</h2>
        <div className="steps-grid">
          <div className="step-item">
            <h3>1. Upload Test Cases</h3>
            <p>
              Easily create or upload your custom test cases, or use our
              pre-built tests.
            </p>
          </div>
          <div className="step-item">
            <h3>2. Define Test Environment</h3>
            <p>
              Select platforms, devices, and browsers you want to run tests on.
            </p>
          </div>
          <div className="step-item">
            <h3>3. Review Results</h3>
            <p>
              Receive detailed reports with logs and screenshots of test
              results.
            </p>
          </div>
        </div>
      </section>

      {/* <!-- Pricing Section --> */}
      <section id="pricing" className="pricing">
        <h2>Pricing Plans</h2>
        <div className="pricing-grid">
          <div className="pricing-item">
            <h3>Starter</h3>
            <p>
              Per test case: charged based on the number of test cases written
              and executed. Email support.
            </p>
            <p className="price">$50–$200/test case</p>
            <a href="#get-started" className="btn-primary">
              Choose Plan
            </a>
          </div>
          <div className="pricing-item">
            <h3>Pro</h3>
            <p>
              Unlimited projects, 5,000 test runs, CI/CD integration, priority
              support.
            </p>
            <p className="price">$50–$150/hour</p>
            <a href="#get-started" className="btn-primary">
              Choose Plan
            </a>
          </div>
          <div className="pricing-item">
            <h3>Enterprise</h3>
            <p>
              Custom test runs, dedicated environments, full integration
              support.
            </p>
            <p className="price">Custom</p>
            <a href="#contact" className="btn-primary">
              Contact Sales
            </a>
          </div>
        </div>
      </section>

      {/* <!-- Contact Section --> */}
      <section id="contact" className="contact">
        <h2>Contact Us</h2>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSccq1-Py7ABgshyhZuLOLIe6bvcZoE0KPx1wpfk3O5--lwQ7Q/viewform?embedded=true"
          width="640"
          height="1011"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </section>
    </>
  );
};
