export const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 QA Automation. All Rights Reserved.</p>
      <ul className="footer-links">
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        <li>
          <a href="#">Terms & Conditions</a>
        </li>
        <li>
          <a href="mailto:sales@creativeclickmultimedia.com">
            contact us at sales@creativeclickmultimedia.com
          </a>
        </li>
      </ul>
    </footer>
  );
};
