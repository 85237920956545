// import "firebase/compat/database";
// import { initializeApp } from "firebase/app";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Sections } from "./components/Sections";
import "./App.css";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
// const firebaseConfig = {
//   // ...
//   // The value of `databaseURL` depends on the location of the database
//   databaseURL: "https://creativeclick.firebaseio.com",
// };

// Initialize Firebase
// initializeApp(firebaseConfig);

// // Initialize Realtime Database and get a reference to the service
// const database = firebase.database();
// console.log("data::", database);

function App() {
  return (
    <>
      <Header />
      <Sections />
      <Footer />
    </>
  );
}

export default App;
