import logo from "../assets/images/logo.svg";
export const Header = () => {
  return (
    <header>
      <nav>
        <div>
          <img className="logo" src={logo} />
        </div>
        <ul className="nav-links">
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="#how-it-works">How It Works</a>
          </li>
          <li>
            <a href="#pricing">Pricing</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <a href="#contact" className="cta">
          Get Started
        </a>
      </nav>
      <section className="hero">
        <h1>Effortless, Reliable Automated QA for Software Apps</h1>
        <p>
          Accelerate your software development with comprehensive test
          automation. Reduce manual effort and ensure flawless applications.
        </p>
        <a href="#contact" className="btn-primary">
          Get Started for Free
        </a>
        <a href="#contact" className="btn-secondary">
          Request a Demo
        </a>
      </section>
    </header>
  );
};
